import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Banner from "../../components/banner/banner";
import ProjectList from "../../components/project-item/ProjectList";
import Image from "./../../assets/images/banners/projects.jpg";
import Background from "./../../assets/images/bg.jpg";
import { ProjectData } from "./../../components/project-item/interfaces"; // Import the interface

const Projects: React.FC = React.memo(() => {
  const [projectsData, setProjectsData] = useState<ProjectData[]>([]);
  const [activeCategory, setActiveCategory] = useState<string>("Alla");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get<ProjectData[]>("/projects.json");
        setProjectsData(response.data);
        console.log("Fetched Projects Data:", response.data);
      } catch (err) {
        setError("An error occurred while fetching data. Please try again later.");
      }
    }

    fetchData();
  }, []);

  const filterProjects = useCallback(
    (category: string) => {
      if (category === "Alla") {
        return projectsData;
      }
      return projectsData.filter((project) => project.category.includes(category));
    },
    [projectsData]
  );

  useEffect(() => {
    console.log("Active Category:", activeCategory);
    console.log("Filtered Projects:", filterProjects(activeCategory));
  }, [activeCategory, filterProjects]);

  return (
    <>
      <Banner title="Våra projekt" image={Image} />

      <section className="w-full relative overflow-hidden flex justify-center items-center mb-[2.5%]">
        <div className="flex justify-center items-center w-full h-full z-10 bg-[#0f1920] bg-opacity-[85%] py-10 px-10">
          <div className="flex flex-wrap max-2xl:gap-15 max-xl:gap-10 max-lg:gap-7 max-md:grid-cols-2 max-md:gap-x-5 gap-5">
            {["Alla", "Entreprenad", "Stambyte", "Energispar", "Data", "Service", "Styrteknik"].map((category) => (
              <button
                key={category}
                className={`border-white border rounded-lg px-5 py-1 text-xs text-white uppercase tracking-wide bg-white ${
                  activeCategory === category ? "bg-opacity-60" : "bg-opacity-20"
                } duration-300 ease-in-out hover:bg-opacity-60`}
                onClick={() => setActiveCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        <div
          className="absolute w-full h-full bg-center bg-cover bg-no-repeat blur-lg"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(15, 25, 32, 0), rgba(15, 25, 32, 0)), url(${Background})`,
          }}
        ></div>
      </section>

      <section className="container mx-auto pb-20 pt-10 max-md:px-[5%]">
        <div className="w-full">
          {error ? (
            <div className="error-message">{error}</div>
          ) : projectsData.length === 0 ? (
            <div>No projects available.</div>
          ) : (
            <ProjectList projects={filterProjects(activeCategory)} />
          )}
        </div>
      </section>
    </>
  );
});

export default Projects;
