import About from "../../../components/about-boxes/about";
import ImageOne from "./../../../assets/images/about-one.jpg";
import ImageTwo from "./../../../assets/images/about-two.jpg";

function Contact() {
  return (
    <section className="container mx-auto pb-20 pt-10 max-md:px-[5%]">
      <div className="w-full">
        <div className="w-full flex items-center max-md:flex-col max-md:gap-10">
          <div className="w-3/5 flex justify-center max-md:w-full">
            <div className="max-w-[35rem] w-[35rem] flex flex-col justify-center max-lg:w-full max-md:w-full">
              <h1 className="text-4xl font-semibold mb-5 max-lg:text-2xl max-lg:mb-2">
                Varför välja Elektropartner?
              </h1>
              <p className="text-normal max-lg:text-sm">
                Elektropartner AB går utöver att bara vara elektriker; vi är 
                hängivna professionella som är betrodda av många kunder. 
                Vårt åtagande för kvalitet syns i varje projekt, vare sig 
                det är bostadsarbete eller avancerade fiber- och data­lösningar. 
                Vi är erkända som fyrfaldig vinnare av priset Elektrikerföretag 
                av Året i Sverige (2018-2021), våra utmärkelser bekräftar vårt engagemang. 
                Välj Elektropartner och upplev skillnaden.
              </p>
              <br />
              <p className="text-normal max-lg:text-sm">
                Vi är din pålitliga samarbetspartner, nära till hands med unika 
                insikter för både hem och företag. Vår expertis och kundfokus 
                säkerställer precision och professionalism. Medan vi är stolta 
                över våra erkännanden, strävar vi alltid efter att förtjäna ditt 
                förtroende. Välj Elektropartner som din elektriska partner och 
                bevittna vår kvalitet och expertis i Sveriges elektriska landskap.
              </p>
              <br />
              <p className="text-normal max-lg:text-sm">
                Kontakta Elektropartner AB idag för elektriska lösningar som prioriterar 
                excellens, tillförlitlighet och kundnöjdhet. Låt oss belysa dina projekt 
                med vår prisbelönta expertis.
              </p>
            </div>
          </div>

          <div className="w-2/5 max-md:w-full">
            <form
              className="flex flex-col gap-5"
              action="postOne.php"
              method="post"
            >
              <div className="w-full flex gap-5">
                <div className="w-1/2">
                  <div className="w-full flex flex-col">
                    <label className="text-sm">Namn</label>
                    <input
                      className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                      type="text"
                      id="name"
                      placeholder="Namn"
                    />
                  </div>
                </div>

                <div className="w-1/2">
                  <div className="w-full flex flex-col">
                    <label className="text-sm">Företag</label>
                    <input
                      className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                      type="text"
                      id="company"
                      placeholder="Företag"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col">
                <label className="text-sm">E-post</label>
                <input
                  className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                  type="email"
                  id="email"
                  placeholder="Din e-post"
                />
              </div>

              <div className="w-full flex flex-col">
                <label className="text-sm">Ditt meddelande</label>
                <textarea
                  className="border-2 bg-gray-100 rounded-lg py-1 px-2 h-[10rem] hover:border-[#0f1920] caret-[#0f1920] transition-all"
                  id="message"
                  placeholder="Skriv ditt meddelande här..."
                ></textarea>
              </div>

              <div className="w-full">
                <input
                  className="border border-[#0f1920] rounded-lg py-2 px-6 text-white bg-[#0f1920] uppercase"
                  type="submit"
                  value="Skicka"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
