import React, { useState } from "react";
import Project from "./Project";
import { ProjectData } from "./interfaces"; // Import the interface

interface ProjectListProps {
  projects: ProjectData[];
}

const ProjectList: React.FC<ProjectListProps> = ({ projects }) => {
  const [visibleProjects, setVisibleProjects] = useState<number>(6);
  const LOAD_MORE_COUNT = 6;

  const loadMore = (): void => {
    setVisibleProjects((prevVisibleProjects) => prevVisibleProjects + LOAD_MORE_COUNT);
  };

  return (
    <div className="w-full gap-10 grid grid-cols-2 max-sm:gap-5">
      {projects.slice(0, visibleProjects).map(({ title, description, image_cover, link, index, category }) => (
        <Project
          key={index}
          title={title}
          description={description}
          image={image_cover}
          link={link}
          index={index}
          category={category.join(", ")} // Join the category array into a single string
        />
      ))}
      <div className="w-full flex justify-center col-span-2">
        {visibleProjects < projects.length && (
          <button
            className="border border-[#0f1920] rounded-lg py-2 px-6 text-white bg-[#0f1920] uppercase"
            onClick={loadMore}
          >
            Load More
          </button>
        )}
      </div>
    </div>
  );
};

export default ProjectList;
