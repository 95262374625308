interface Props {
  image: string;
  title: string;
  textOne: string;
  textTwo: string;
  reverse?: boolean;
}

function About({ ...props }: Props) {
  return (
    <div
      className={`w-full flex gap-5 max-lg:items-center max-md:flex-col ${props.reverse ? "flex-row-reverse" : ""}`}
    >
      <div
        className={`w-2/5 mr-0 max-lg:w-full ${props.reverse ? "!mr-0 ml-0" : ""}`}
      >
        <div
          className="min-h-[25rem] rounded-lg bg-no-repeat bg-center bg-cover"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(15, 25, 32, .35), rgba(15, 25, 32, .35)), url(${props.image})`,
          }}
        ></div>
      </div>

      <div
        className={`w-3/5 flex justify-center max-lg:w-full ${props.reverse ? "justify-center" : ""}`}
      >
        <div className="max-w-[35rem] w-[35rem] flex flex-col justify-center max-lg:w-full max-md:w-full">
          <h1 className="text-4xl font-semibold mb-5 max-lg:text-2xl max-lg:mb-2">
            {props.title}
          </h1>
          <p className="text-normal max-lg:text-sm">{props.textOne}</p>
          <br />
          <p className="text-normal max-lg:text-sm">{props.textTwo}</p>
        </div>
      </div>
    </div>
  );
}

export default About;
