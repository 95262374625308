import React from "react";
import { Link } from "react-router-dom";
import Logo from "./../../assets/logotyp/logo_icon.png";

const Header = ({ navbarOpen, setNavbarOpen }) => {
  const handleTogglePress = () => {
    setNavbarOpen(!navbarOpen);

    document.body.style.overflowY = navbarOpen ? "initial" : "hidden";
  };
  return (
    <header className="w-full py-5 flex justify-center absolute z-50">
      <div className="w-[90%] max-w-[2200px] flex justify-between items-center">
        <Link to="/">
          {/* <h1 className="text-white font-semibold tracking-widest text-xl uppercase">Elektropartner</h1> */}
          <img src={Logo} alt="" className="h-14 w-auto max-md:h-8" />
        </Link>

        <div className="">
          <button
            className="w-[2rem] h-[2rem] max-md:w-[1.5rem] max-md:h-[1.5rem]"
            onClick={handleTogglePress}
          >
            <div className="grid grid-cols-2 grid-rows-2 w-full h-full gap-1">
              <div className="border border-white rounded-sm"></div>
              <div className="border border-white rounded-sm"></div>
              <div className="border border-white rounded-sm"></div>
              <div className="border border-white rounded-sm"></div>
            </div>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
