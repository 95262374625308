import About from "../../../components/about-boxes/about";
import ImageOne from "./../../../assets/images/about-one.jpg";
import ImageTwo from "./../../../assets/images/about-two.jpg";

function Abouts() {
  return (
    <section className="container mx-auto pb-10 pt-20 max-md:px-[5%]">
      <div className="w-full">
        <div className="w-full flex flex-col gap-16">
          <About
            image={ImageOne}
            title="Vilka är vi? Lär känna oss!"
            textOne="Elektropartner är mer än bara elektriker - vi är dedikerade proffs med förtroende från många kunder. Vårt kvalitetsfokus syns i varje projekt, oavsett om det är för bostäder eller avancerad fiber och data. Vi är fyrfaldigt prisade som Årets Elektrikerföretag i Sverige (2018-2021), bekräftelse på vårt engagemang. Välj Elektropartner och upplev skillnaden."
            textTwo="Vi är din pålitliga samarbetspartner, nära och med unik kundinsikt för både hem och företag. Vår expertis och kundfokus garanterar precision och professionalism. Vi är stolta över våra utmärkelser, men strävar alltid efter att förtjäna ditt förtroende. Välj Elektropartner som din elektricitetspartner och upplev vår kvalitet och expertis i Sveriges elektriska landskap."
          />
        </div>
      </div>
    </section>
  );
}

export default Abouts;
