import React, { useState, useEffect } from "react";
import Banner from "../banner/banner";
import ItemStat from "./ItemStat";
import Image from "./../../assets/images/img.jpeg";
import Square from "./../../assets/icons/square.svg";
import Stopwatch from "./../../assets/icons/stopwatch.svg";
import Dollar from "./../../assets/icons/dollar.svg";
import People from "./../../assets/icons/people.svg";
import { useParams } from "react-router-dom";

// Modify the interface to match your data structure
interface ProjectData {
  title: string;
  category: string;
  image_cover: string;
  link: string;

  paragraph_about_the_work: string;

  statistics_kvadratmeter: number;
  statistics_arbetsintervall: number;
  statistics_projekt_omsattning: number;
  statistics_antal_arbetare: number;

  gallery_image_1: string;
  gallery_image_2: string;
  gallery_image_3: string;
  gallery_image_4: string;
}

const ProjectDetails: React.FC = () => {
  const { link } = useParams<{ link: string | undefined }>();
  const [project, setProject] = useState<ProjectData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/projects.json");
        const data: ProjectData[] = await response.json();

        // Find the project with the matching "link"
        const matchingProject = data.find((item) => item.link === link);

        if (matchingProject) {
          setProject(matchingProject);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [link]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!project) {
    return <div>Project not found</div>;
  }

  // Define a function to prepend PUBLIC_URL to image paths
  const getImageUrl = (path: string) => `${process.env.PUBLIC_URL}${path}`;

  return (
    <>
      <Banner
        title={project.title}
        image={`${getImageUrl(project.image_cover)}`}
      ></Banner>

      <section className="w-full flex justify-center pt-[5%] pb-[2.5%]">
        <div className="w-[90%]">
          <div className="grid grid-cols-2 h-[45rem] gap-4 max-lg:h-[30rem] max-sm:h-[25rem]">
            <div
              className="col-span-1 rounded-lg bg-cover bg-center bg-no-repeat max-sm:col-span-2"
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(15, 25, 32, 0.35), rgba(15, 25, 32, 0.35)), url(${getImageUrl(project.gallery_image_1)})`, // Use getImageUrl for galleryOne
              }}
            ></div>
            <div
              className="col-span-1 rounded-lg bg-cover bg-center bg-no-repeat max-sm:col-span-1"
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(15, 25, 32, 0.35), rgba(15, 25, 32, 0.35)), url(${getImageUrl(project.gallery_image_2)})`, // Use getImageUrl for galleryTwo
              }}
            ></div>
            <div
              className="col-span-1 rounded-lg bg-cover bg-center bg-no-repeat max-sm:col-span-1"
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(15, 25, 32, 0.35), rgba(15, 25, 32, 0.35)), url(${getImageUrl(project.gallery_image_3)})`, // Use getImageUrl for galleryThree
              }}
            ></div>
            <div
              className="col-span-1 rounded-lg bg-cover bg-center bg-no-repeat max-sm:hidden"
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(15, 25, 32, 0.35), rgba(15, 25, 32, 0.35)), url(${getImageUrl(project.gallery_image_4)})`, // Use getImageUrl for galleryFour
              }}
            ></div>
          </div>
        </div>
      </section>

      <section className="w-full relative overflow-hidden flex justify-center items-center mt-[2.5%] mb-[2.5%] h-[35vh] max-h-[18rem] ">
        <div className="flex justify-center items-center w-full h-full z-10 bg-[#0f1920] bg-opacity-[85%]">
          <div className="grid grid-cols-4 gap-20 max-2xl:gap-15 max-xl:gap-10 max-lg:gap-7 max-md:grid-cols-2 max-md:gap-x-10 gap-">
            <ItemStat
              icon={Square}
              number={`${project.statistics_kvadratmeter}`}
              value="㎡"
              text="Kvadratmeter"
            />
            <ItemStat
              icon={Stopwatch}
              number={`${project.statistics_arbetsintervall}`}
              value="days"
              text="Arbetsintervall"
            />
            <ItemStat
              icon={Dollar}
              number={`${project.statistics_projekt_omsattning}`}
              value="mjkr"
              text="Projekt omsättning"
            />
            <ItemStat
              icon={People}
              number={`${project.statistics_antal_arbetare}`}
              value="st"
              text="Antal arbetare"
            />
          </div>
        </div>

        <div
          className="absolute w-full h-full bg-center bg-cover bg-no-repeat blur-lg"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(15, 25, 32, 0), rgba(15, 25, 32, 0)), url(${Image})`,
          }}
        ></div>
      </section>

      <section className="w-full flex justify-center pt-[2.5%] pb-[5%]">
        <div className="w-[80%]">
          <div className="w-full flex items-center gap-20 max-sm:flex-col max-sm:gap-10">
            <div className="w-1/2 mr-0 max-lg:w-full">
              <div
                className="min-h-[25rem] rounded-lg bg-no-repeat bg-center bg-cover"
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(15, 25, 32, .35), rgba(15, 25, 32, .35)), url(${project.image_cover})`,
                }}
              ></div>
            </div>

            <div className="flex flex-col gap-2 w-1/2 max-sm:w-full">
              <h1 className="text-3xl tracking-wider font-semibold text-[#222] max-sm:text-xl">
                Om vårat arbete
              </h1>
              <p className="text-[#222] max-sm:text-sm">{project.paragraph_about_the_work}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectDetails;
