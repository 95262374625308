interface Props {
  title: string;
  text: string;
  icon: string;
  alt: string;
  duration: number;
}

function Offers({ ...props }: Props) {
  return (
    <div
      className={`flex flex-col gap-4 items-center w-1/3 h-full max-lg:h-auto max-lg:w-[40%] max-md:w-full max-md:gap-2 max-sm:w-full max-sm:gap-2 delay-[300ms] duration-[${props.duration}] taos:translate-y-[200px] taos:opacity-0`}
      data-taos-offset="300"
    >
      <div className="w-full flex justify-start items-center gap-5">
        <img
          src={props.icon}
          alt=""
          className="w-auto h-8 max-md:h-5 max-sm:h-5"
        />
        <h1 className="font-semibold text-xl tracking-wider upper text-[#222] max-lg:text-xl max-md:text-lg max-sm:text-lg">
          {props.title}
        </h1>
      </div>
      <p className="text-[#222] max-md:text-sm max-sm:text-sm">{props.text}</p>
    </div>
  );
}

export default Offers;
