import Partner from "../../../components/partner-boxes/partner";
import Image from "./../../../assets/images/bg.jpg";
import Commscope from "./../../../assets/images/partners/commscope.png";
import DoRunner from "./../../../assets/images/partners/do_runner.png";
import ForetagForbundet from "./../../../assets/images/partners/foretagar_forbundet.png";
import ID06 from "./../../../assets/images/partners/id_06.png";
import IN from "./../../../assets/images/partners/in.png";
import SvensktNaringsliv from "./../../../assets/images/partners/svenskt_naringsliv.png";
import Tesla from "./../../../assets/images/partners/tesla.png";

function Partners() {
  return (
    <section className="w-full flex justify-center relative overflow-hidden">
      <div className="w-full h-full flex justify-center bg-[#0f1920] bg-opacity-[85%] z-10">
        <div className="container mx-auto">
          <div className="w-full flex justify-center">
            <div className="w-full grid grid-cols-12 max-md:grid-cols-3 max-sm:grid-cols-2 py-[2.5%]">
              <Partner
                img={Commscope}
                url="https://www.commscope.com/"
                alt=""
                column3
              />
              <Partner
                img={DoRunner}
                url="https://www.dorunner.se/"
                alt=""
                column3
              />
              <Partner
                img={ForetagForbundet}
                url="https://ff.se/"
                alt=""
                column3
              />
              <Partner img={ID06} url="https://www.id06.se/" alt="" column3 />
              <Partner img={IN} url="https://www.in.se/" alt="" column4 />
              <Partner
                img={SvensktNaringsliv}
                url="https://www.svensktnaringsliv.se/"
                alt=""
                column4
              />
              <Partner
                img={Tesla}
                url="https://www.tesla.com/"
                alt=""
                column4
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="absolute w-full h-full bg-center bg-cover bg-no-repeat blur-lg"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(15, 25, 32, 0), rgba(15, 25, 32, 0)), url(${Image})`,
        }}
      ></div>
    </section>
  );
}

export default Partners;
