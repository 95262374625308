import React, { useState } from "react";

function ContactForm() {
  const [showForm1, setShowForm1] = useState(true);

  const toggleForm = () => {
    setShowForm1((prevState) => !prevState);
  };

  return (
    <div className="w-full">
      {showForm1 ? (
        <form
          className="flex flex-col gap-5"
          action="postOne.php"
          method="post"
        >
          <div className="w-full flex gap-5">
            <div className="w-1/2">
              <div className="w-full flex flex-col">
                <label className="text-sm">Namn</label>
                <input
                  className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                  type="text"
                  id="name"
                  placeholder="Namn"
                />
              </div>
            </div>

            <div className="w-1/2">
              <div className="w-full flex flex-col">
                <label className="text-sm">Företag</label>
                <input
                  className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                  type="text"
                  id="company"
                  placeholder="Företag"
                />
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col">
            <label className="text-sm">E-post</label>
            <input
              className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
              type="email"
              id="email"
              placeholder="Din e-post"
            />
          </div>

          <div className="w-full flex flex-col">
            <label className="text-sm">Ditt meddelande</label>
            <textarea
              className="border-2 bg-gray-100 rounded-lg py-1 px-2 h-[10rem] hover:border-[#0f1920] caret-[#0f1920] transition-all"
              id="message"
              placeholder="Skriv ditt meddelande här..."
            ></textarea>
          </div>

          <div className="w-2/2">
            <p className="text-[#222] text-base font-semibold">
              <span className="">Vill du göra en order?</span>{" "}
              <a
                href="#"
                onClick={toggleForm}
                className="underline text-blue-600 font-semibold"
              >
                Tryck här
              </a>{" "}
              för vårat orderformulär!
            </p>
          </div>

          <div className="w-full">
            <input
              className="border border-[#0f1920] rounded-lg py-2 px-6 text-white bg-[#0f1920] uppercase"
              type="submit"
              value="Skicka"
            />
          </div>
        </form>
      ) : null}

      {!showForm1 ? (
        <form
          className="grid grid-cols-2 gap-5"
          action="postTwo.php"
          method="post"
        >
          <div className="col-span-1">
            <div className="w-full flex flex-col">
              <label className="text-sm">Namn</label>
              <input
                className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                type="text"
                id="name"
                placeholder="Namn"
              />
            </div>
          </div>

          <div className="col-span-1">
            <div className="w-full flex flex-col">
              <label className="text-sm">Personnummer</label>
              <input
                className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                type="text"
                id="socialnumber"
                placeholder="Personnummer"
              />
            </div>
          </div>

          <div className="col-span-1">
            <div className="w-full flex flex-col">
              <label className="text-sm">Gatuadress</label>
              <input
                className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                type="text"
                id="address"
                placeholder="Gatuadress"
              />
            </div>
          </div>

          <div className="col-span-1">
            <div className="w-full flex flex-col">
              <label className="text-sm">Postadress</label>
              <input
                className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                type="text"
                id="mailing"
                placeholder="Postadress"
              />
            </div>
          </div>

          <div className="col-span-1">
            <div className="w-full flex flex-col">
              <label className="text-sm">Telefon</label>
              <input
                className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                type="tel"
                id="phone"
                placeholder="Telefon"
              />
            </div>
          </div>

          <div className="col-span-1">
            <div className="w-full flex flex-col">
              <label className="text-sm">Fastighet/lägenhetsbeteckning</label>
              <input
                className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                type="text"
                id="designation"
                placeholder="Beteckning"
              />
            </div>
          </div>

          <div className="col-span-1">
            <div className="w-full flex flex-col">
              <label className="text-sm">Föreningens organisationsnr</label>
              <input
                className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                type="number"
                id="designation"
                placeholder="Organisationsnr"
              />
            </div>
          </div>

          <div className="col-span-1">
            <div className="w-full flex flex-col">
              <label className="text-sm">E-post</label>
              <input
                className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
                type="email"
                id="email"
                placeholder="E-post"
              />
            </div>
          </div>

          <div className="col-span-2">
            <div className="w-full flex flex-col">
              <label className="text-sm">Ditt meddelande</label>
              <textarea
                className="border-2 bg-gray-100 rounded-lg py-1 px-2 h-[10rem] hover:border-[#0f1920] caret-[#0f1920] transition-all"
                id="message"
                placeholder="Skriv ditt meddelande här..."
              ></textarea>
            </div>
          </div>

          <div className="col-span-2">
            <div className="w-full flex">
              <p className="text-[#222] text-base">
                <span className="font-semibold">Vill du kontakta oss?</span>{" "}
                <a
                  href="#"
                  onClick={toggleForm}
                  className="underline text-blue-600 font-semibold"
                >
                  Tillbaka till första formuläret.
                </a>
              </p>
            </div>
          </div>

          <div className="col-span-2">
            <div className="w-full">
              <input
                className="border border-[#0f1920] rounded-lg py-2 px-6 text-white bg-[#0f1920] uppercase"
                type="submit"
                value="Skicka"
              />
            </div>
          </div>
        </form>
      ) : null}
    </div>
  );
}

export default ContactForm;
