import { Link } from "react-router-dom";

interface Props {
  title: string;
  description: string;
  image: string;
  link: string;
  index: number;
  category: string;
}

function Project({ title, description, image, link, index }: Props) {
  const getImageUrl = (path: string) => `${process.env.PUBLIC_URL}${path}`;

  return (
    <Link to={`/projekt/${link}`} className="col-span-1 max-lg:col-span-2">
      <div className="flex justify-start overflow-hidden relative items-end rounded-lg h-[25rem] max-sm:h-[16rem] group hover:cursor-pointer">
        <div className="flex flex-col z-10 pl-10 py-5 border-t border-white border-opacity-30 overflow-hidden bg-[#0f1920] bg-opacity-50 w-full backdrop-blur-md max-md:py-3 max-md:pl-5">
          <h1 className="text-white text-2xl font-semibold tracking-wider uppercase max-sm:text-lg">
            {title}
          </h1>
          <p className="text-white opacity-80 max-sm:text-xs">{description}</p>
        </div>

        <div
          className="absolute w-full h-full bg-center bg-cover bg-no-repeat border-lg group-hover:scale-110 duration-300 ease-in-out"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .35), rgba(0,0,0, .35)), url(${getImageUrl(image)})`,
          }}
        ></div>
      </div>
    </Link>
  );
}

export default Project;
