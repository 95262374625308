interface Props {
  title: string;
  image: string;
}

function Banner({ ...props }: Props) {
  return (
    <section
      className="w-full h-[40vh] flex justify-center items-center bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(15,25,32, .75), rgba(15,25,32, .75)), url(${props.image})`,
      }}
    >
      <h1 className="text-6xl text-white font-semibold tracking-wider max-lg:text-4xl max-sm:text-3xl">
        {props.title}
      </h1>
    </section>
  );
}

export default Banner;
