import { CountUp } from "use-count-up";

interface Props {
  icon: string;
  number: string;
  value: string;
  text: string;
}

function ItemStat({ ...props }: Props) {
  const numberAsNumber = parseFloat(props.number);

  return (
    <div className="flex col-span-1 gap-4 justify-center">
      <div className="h-full flex justify-center">
        <img className="h-full w-auto" src={props.icon} alt="" />
      </div>

      <div className="flex flex-col">
        <h1 className="text-white tracking-wider font-semibold text-3xl max-lg:text-xl">
          <CountUp isCounting end={numberAsNumber} duration={3.2} />
          <span className="uppercase">{props.value}</span>
        </h1>
        <p className="text-white text-sm max-lg:text-xs">{props.text}</p>
      </div>
    </div>
  );
}

export default ItemStat;
