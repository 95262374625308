import Banner from "../../components/banner/banner";
import Image from "./../../assets/images/banners/apply.jpg";
import FormContainer from "./sections/FormContainer";

function Apply() {
  return (
    <>
      <Banner title="Ansök om jobb" image={Image} />
      <FormContainer />
    </>
  );
}

export default Apply;
