import ContactForm from "../../../components/contact-form/form";
import GoogleMaps from "../../../components/google-maps/GoogleMaps";

function ContactSection() {
  return (
    <section className="container mx-auto pb-20 pt-10 max-md:px-[5%]">
      <div className="w-full">
        <div className="w-full flex justify-center items-center gap-20 max-lg:gap-20 max-lg:flex-col-reverse">
          <div className="w-1/2 h-full max-lg:w-full">
            <div className="flex justify-center w-full h-[25rem] overflow-hidden rounded-lg max-lg:h-[18rem]">
              <GoogleMaps />
            </div>
          </div>

          <div className="w-1/2 max-lg:w-full">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
