import ApplyForm from "./../../../components/apply-form/Form";
import JobPosts from "./../../../components/job-post/JobPosts";

interface Props {}

function FormContainer({ ...props }: Props) {
  return (
    <>
      <section className="container mx-auto pb-20 pt-20 max-md:px-[5%]">
        <div className="w-full h-full flex items-center max-lg:flex-col max-lg:gap-16">
          <div className="w-1/2 flex max-lg:w-full">
            <div className="w-full">
              <JobPosts />
            </div>
          </div>

          <div className="w-1/2 max-lg:w-full">
            <ApplyForm />
          </div>
        </div>
      </section>
    </>
  );
}

export default FormContainer;
