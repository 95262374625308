import React, { useEffect, useState } from "react";
import axios from "axios";
import JobPost from "./JobPost"; // Import your JobPost component

const JobPosts: React.FC = () => {
  const [jobData, setJobData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [displayedPosts, setDisplayedPosts] = useState<number>(6); // Start by displaying 6 posts

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch data from the JSON file (change the path to your jobData.json)
        const response = await axios.get("/jobs.json");

        // Use the JSON data directly
        const data = response.data;

        console.log("Fetched job data:", data); // Log the fetched data

        setJobData(data);
      } catch (error) {
        console.error("Error fetching job data:", error);
        setError(
          "An error occurred while fetching job data. Please try again later.",
        );
      }
    }

    fetchData();
  }, []);

  // Function to load more job posts (6 at a time)
  const loadMorePosts = () => {
    setDisplayedPosts(displayedPosts + 4); // Increase the number of displayed posts by 6
  };

  return (
    <div className="w-[90%] h-full flex items-center justify-center max-lg:w-full">
      <div className="w-full grid grid-cols-1 gap-5">
        {/* Map over jobData and create a JobPost component for each entry, limited by displayedPosts */}
        {jobData.slice(0, displayedPosts).map((item, index) => (
          <JobPost
            key={index}
            title={item.title}
            location={item.location}
            date={item.date}
            description={item.description}
          />
        ))}
      </div>
    </div>
  );
};

export default JobPosts;
