import ContactInfo from "../../../components/contact-info/info";
import James from "./../../../assets/images/portraits/james.jpg";
import Jakob from "./../../../assets/images/portraits/jakob.jpg";
import Fernando from "./../../../assets/images/portraits/fernando.jpg";
import Filip from "./../../../assets/images/portraits/filip.jpeg";
import Oliver from "./../../../assets/images/portraits/oliver.jpeg"

function Workers() {
  return (
    <section className="container mx-auto pb-10 pt-20 max-md:px-[5%]">
      <div className="w-full">
        <div className="grid grid-cols-5 gap-20 max-xl:gap-[2%] max-lg:grid-cols-2 max-md:grid-cols-1 max-md:grid-rows-4 max-md:gap-10">
          <ContactInfo
            name="James Crawford"
            title="VD"
            email="james@elektropartner.nu"
            phone="08- 30 17 07"
            image={James}
          />
          <ContactInfo
            name="Jakob Zigmond"
            title="Vice VD"
            email="jakob@elektropartner.nu"
            phone="08- 30 17 05"
            image={Jakob}
          />
          <ContactInfo
            name="Fernando Cures"
            title="Projektledare"
            email="fernando@elektropartner.nu"
            phone="076-495 60 77"
            image={Fernando}
          />
          <ContactInfo
            name="Oliver Malm"
            title="Projektledare"
            email="oliver@elektropartner.nu"
            phone="076-495 60 99"
            image={Oliver}
          />
          <ContactInfo
            name="Filip Almqvist"
            title="Projektledare"
            email="filip@elektropartner.nu"
            phone="076-495 60 88"
            image={Filip}
          />
        </div>
      </div>
    </section>
  );
}

export default Workers;
