import Banner from "../../components/banner/banner";
import BannerImage from "../../assets/images/banners/styrteknik.jpg";
import { Services, Projects, About } from "./sections/index";

function Styrteknik() {
  return (
    <>
        <Banner title="Styrteknik" image={BannerImage} />
        <Services />
        <Projects />
        <About />
    </>
  );
}

export default Styrteknik;
