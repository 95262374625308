import { Link } from "react-router-dom";
import Logo from "./../../assets/logotyp/logo_rounded.png";
import Image from "./../../assets/images/bg.jpg";

function Footer() {
  return (
    <footer className="w-full flex flex-col items-center relative overflow-hidden">
      <div className="w-full h-full flex flex-col items-center bg-[#0f1920] bg-opacity-[85%] z-10">
        <div className="w-[90%] flex justify-between pt-[3%] pb-[2%] max-sm:pt-[5%]">
          <div className="flex items-center justify-start gap-2 max-[567px]:hidden">
            <Link to="/">
              <img src={Logo} alt="" className="w-40 h-auto max-lg:w-28"></img>
            </Link>
          </div>

          <div className="flex justify-evenly w-[80%] max-[567px]:justify-between max-sm:w-[90%]">
            <div className=" flex flex-col gap-2 max-sm:gap-1">
              <h1 className="text-2xl font-semibold text-white tracking-wide pb-[15px] max-lg:text-lg max-sm:pb-[5px]">
                Kontakt info
              </h1>
              <div className="flex flex-col gap-2 max-sm:gap-1">
                <a href="mailto:info@elektropartner.nu" rel="noreferrer">
                  <span className="text-white text-sm max-lg:text-xs">
                    info@elektropartner.nu
                  </span>
                </a>
                <a href="tel:+46 810 41 22" rel="noreferrer">
                  <span className="text-white text-sm max-lg:text-xs">
                    +46 810 41 22
                  </span>
                </a>
                <p>
                  <span className="text-white text-sm max-lg:text-xs">
                    Box 1113<br></br>131 26 Nacka Strand
                  </span>
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-2 max-sm:hidden">
              <h1 className="text-2xl font-semibold text-white tracking-wide pb-[15px] max-lg:text-lg">
                Navigator
              </h1>
              <div className="flex flex-col gap-2">
                <Link to="/nyheter">
                  <span className="text-white text-sm max-lg:text-xs">
                    Nyheter
                  </span>
                </Link>
                <Link to="/projekt">
                  <span className="text-white text-sm max-lg:text-xs">
                    Projekt
                  </span>
                </Link>
                <Link to="/kontakta-oss">
                  <span className="text-white text-sm max-lg:text-xs">
                    Kontakta oss
                  </span>
                </Link>
                <Link to="/jobb">
                  <span className="text-white text-sm max-lg:text-xs">
                    Ansök om jobb
                  </span>
                </Link>
              </div>
            </div>

            <div className=" flex flex-col gap-2 max-sm:gap-1">
              <h1 className="text-2xl font-semibold text-white tracking-wide pb-[15px] max-lg:text-lg max-sm:pb-[5px] max-sm:text-base">
                Elektropartner AB
              </h1>
              <div className="flex flex-col gap-2 max-sm:gap-1">
                <p>
                  <span className="text-white text-sm max-lg:text-xs">
                    Cylindervägen 18, Plan 5<br></br>131 52 Nacka Strand
                  </span>
                </p>
                <p>
                  <span className="text-white text-sm max-lg:text-xs">
                    Org-nr: 556966-6265
                  </span>
                </p>
                <p>
                  <span className="text-white text-sm max-lg:text-xs">
                    VAT-nr: SE556966626501
                  </span>
                </p>
                <p>
                  <span className="text-white text-sm max-lg:text-xs">
                    Bankgiro: 536-9178
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[90%] flex justify-between pb-[20px] max-sm:justify-center">
          <p className="text-white opacity-75 text-[10px]">
            © 2023 Elektropartner Nacka AB, All Rights Reserved.
          </p>
          <p className="text-white opacity-75 text-[10px] max-sm:hidden">
            Made by{" "}
            <a
              className="font-bold"
              href="https://infinet.se"
              target="_blank"
              rel="noopener"
            >
              Infinet
            </a>
          </p>
        </div>
      </div>

      <div
        className="absolute w-full h-full bg-center bg-cover bg-no-repeat blur-lg"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(15, 25, 32, 0), rgba(15, 25, 32, 0)), url(${Image})`,
        }}
      ></div>
    </footer>
  );
}

export default Footer;
