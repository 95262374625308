import Banner from "../../components/banner/banner";
import ContactSection from "./sections/ContactContainer";
import Workers from "./sections/Workers";
import Image from "./../../assets/images/banners/contact.jpg";

function Contact() {
  return (
    <>
      <Banner title="Kontakta oss" image={Image} />
      <Workers />
      <ContactSection />
    </>
  );
}

export default Contact;
