import Email from "./../../assets/icons/envelope-solid.svg";
import Phone from "./../../assets/icons/phone-solid.svg";

interface Props {
  email: string;
  phone: string;
  name: string;
  title: string;
  image: string;
}

function ContactInfo({ ...props }: Props) {
  return (
    <div className="col-span-1 flex flex-col max-lg:flex-col max-lg:gap-3 max-lg:items-center">
      <div className="w-auto h-[18rem] pb-3 max-lg:w-[15rem] max-md:w-[70%] max-md:h-[22rem]">
        <div
          className="rounded-lg bg-cover bg-no-repeat bg-center w-full h-full"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0)), url(${props.image})`,
          }}
        ></div>
      </div>

      <div className="flex flex-col gap-3">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-lg font-semibold tracking-wide">{props.name}</h1>
          <p className="text-xs">{props.title}</p>
        </div>
        <div className="flex flex-col items-center gap-2">
          <a href={`mailto:${props.email}`} className="flex gap-2 items-center">
            <img className="w-3 h-auto" src={Email} alt="email icon" />
            <p className="text-[#222] text-xs">{props.email}</p>
          </a>

          <a href={`tel:${props.phone}`} className="flex gap-2 items-center">
            <img className="w-3 h-auto" src={Phone} alt="phone icon" />
            <p className="text-[#222] text-xs">{props.phone}</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
