function ApplyForm() {
  return (
    <>
      <div className="w-full">
        <form
          className="grid grid-cols-2 gap-5"
          action="post.php"
          method="post"
        >
          <div className="flex flex-col col-span-1">
            <label className="text-sm">Förnamn:</label>
            <input
              type="text"
              id="full_name"
              name="full_name"
              placeholder="Förnamn"
              required
              className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-sm">Efternamn:</label>
            <input
              type="text"
              id="full_name"
              name="full_name"
              placeholder="Efternamn"
              required
              className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-sm">Telefon:</label>
            <input
              type="tel"
              id="phone_number"
              name="phone_number"
              placeholder="Telefon"
              required
              className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-sm">E-post:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Din e-post"
              required
              className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-sm">Adress:</label>
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Din adress"
              className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-sm">Födelsedag:</label>
            <input
              type="date"
              id="date_of_birth"
              name="date_of_birth"
              required
              className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-sm">Tjänst ansökt om:</label>
            <select
              id="position_applied"
              name="position_applied"
              required
              className="border-2 bg-gray-100 rounded-lg py-1 px-2 hover:border-[#0f1920] caret-[#0f1920] transition-all w-[60%]"
            >
              <option value="electrician">Service Elektriker</option>
              <option value="electrician">Entreprenad-Elektriker</option>
              <option value="electrician">Admin / Kalkyl</option>
            </select>
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-sm">Ladda upp CV:</label>
            <input
              type="file"
              id="resume"
              name="resume"
              accept=".pdf,.doc,.docx"
              required
              className=""
            />
          </div>

          <div className="flex flex-col col-span-2">
            <label className="text-sm">Ditt meddelande</label>
            <textarea
              className="border-2 bg-gray-100 rounded-lg py-1 px-2 h-[10rem] hover:border-[#0f1920] caret-[#0f1920] transition-all"
              id=""
              placeholder="Skriv ditt meddelande här..."
            ></textarea>
          </div>

          <div className="col-span-1">
            <input
              className="border border-[#0f1920] rounded-lg py-2 px-6 text-white bg-[#0f1920] uppercase"
              type="submit"
              value="Ansök nu"
            ></input>
          </div>
        </form>
      </div>
    </>
  );
}

export default ApplyForm;
