import React, { useEffect, useState } from "react";
import axios from "axios";
import Post from "../../components/news-item/post";
import Banner from "../../components/banner/banner";
import Image from "./../../assets/images/banners/news.jpg";

const News: React.FC = () => {
  const [newsData, setNewsData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [displayedPosts, setDisplayedPosts] = useState<number>(6); // Start by displaying 6 posts

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch data from the JSON file
        const response = await axios.get("/news.json");

        // Use the JSON data directly
        const data = response.data;

        console.log("Fetched data:", data); // Log the fetched data

        setNewsData(data);

        // Add console.log statements to inspect individual fields of an item
        if (data.length > 0) {
          const firstItem = data[0];
          console.log("Cover of the first item:", firstItem.cover);
          console.log("Description of the first item:", firstItem.description);
          console.log("Link of the first item:", firstItem.link);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(
          "An error occurred while fetching data. Please try again later.",
        );
      }
    }

    fetchData();
  }, []);

  // Function to load more posts (6 at a time)
  const loadMorePosts = () => {
    setDisplayedPosts(displayedPosts + 6); // Increase the number of displayed posts by 6
  };

  return (
    <>
      <Banner image={Image} title="Nyheter"></Banner>
      <section className="container mx-auto pb-20 pt-20 max-md:px-[5%]">
        <div className="w-full">
          <div className="w-full px-0 gap-10 grid grid-cols-2 max-lg:grid-cols-1 max-lg:gap-14">
            {/* Map over newsData and create a Post component for each entry, limited by displayedPosts */}
            {newsData.slice(0, displayedPosts).map((item, index) => (
              <Post
                key={index}
                image={item.image_cover}
                cover={item.image_cover}
                date={item.post_date}
                title={item.title}
                description={item.paragraph}
                link={item.link}
              />
            ))}
          </div>
          <div className="flex justify-center pt-[5%]">
            {displayedPosts < newsData.length && (
              <button
                className="border border-[#0f1920] rounded-lg py-2 px-6 text-white bg-[#0f1920] uppercase"
                onClick={loadMorePosts}
              >
                Load more
              </button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default News;
