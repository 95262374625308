import React from "react";
import { Link } from "react-router-dom";
import Image from "../../../assets/images/banners/apply.jpg";

function Projects() {
    return (
        <div className="bg-[#0f1920] w-full max-md:px-[5%]">
            <section className="container mx-auto pb-20 pt-20 text-white">
                <div className="w-full flex max-md:flex-col-reverse max-md:gap-10">
                    <div className="w-7/12 flex flex-col justify-between max-md:w-full">
                        <div className="flex flex-col gap-1.5">
                            <h3 className="text-lg font-normal uppercase">Vårat senaste projekt</h3>
                            <h1 className="text-5xl font-semibold">Styrteknik projekt</h1>
                        </div>

                        <div className="flex flex-col gap-10">
                            <p className="pr-[15%] text-white/85">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla molestiae minima architecto facilis earum deleniti! Omnis, iure quidem. Assumenda minima ex nesciunt sint consequatur, perferendis aliquam labore commodi atque architecto!</p>
                            <div className="flex justify-start">
                                <Link to="">
                                    <span className="px-6 py-3 uppercase bg-white border text-[#0f1920] hover:bg-transparent hover:text-white transition-all duration-300">Visa projekt</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="w-5/12 max-md:w-full">
                        <img className="h-full w-auto object-fill max-md:h-auto max-md:w-full" src={Image} alt="" />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Projects;