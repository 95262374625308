interface Props {
  title: string;
  location: string;
  date: string;
  description: string;
}

function JobPost({ ...props }: Props) {
  return (
    <>
      <div className="flex flex-col items-start justify-between gap-2 col-span-1 row-span-1 border bg-white border-[#0f1920] py-3 px-2 rounded-lg group duration-300 ease-in-out hover:bg-[#0f1920] hover:cursor-pointer max-lg:">
        <div className="w-full flex justify-between">
          <div className="h-full flex items-center justify-start">
            <h1 className="text-lg font-semibold capitalize tracking-wide group-hover:text-white max-md:text-sm">
              {props.title}
            </h1>
          </div>

          <div className="h-full flex items-center justify-start">
            <p className="text-base tracking-wide group-hover:text-white max-md:text-sm">
              {props.location}
            </p>
          </div>

          <div className="h-full flex items-center justify-start">
            <p className="text-base tracking-wide group-hover:text-white max-md:text-sm">
              {props.date}
            </p>
          </div>
        </div>

        <div className="w-full">
          <p className="text-sm group-hover:text-white max-md:text-xs">
            {props.description}
          </p>
        </div>
      </div>
    </>
  );
}

export default JobPost;
