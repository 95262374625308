import React from "react";
import { Link } from "react-router-dom";
import James from "../../../assets/images/portraits/james.jpg";
import Jakob from "../../../assets/images/portraits/jakob.jpg";

interface UsermapItem {
    image: string;
    name: string;
    title: string;
    email: string;
}

const usermapItems: UsermapItem[] = [
    {
        image: James,
        name: 'James Crawford',
        title: 'VD',
        email: 'james@elektropartner.nu'
    },
    {
        image: Jakob,
        name: 'Jakob Zigmond',
        title: 'Vice VD',
        email: 'jakob@elektropartner.nu'
    },
];

const About: React.FC = () => {
    return (
        <section className="container mx-auto pb-20 pt-10 max-md:px-[5%]">
            <div className="flex w-full max-md:flex-col-reverse max-md:gap-10">
                <div className="w-1/2 flex flex-col justify-center max-md:w-full">
                    <div className="pb-10">
                        <h3 className="text-lg font-normal uppercase">Mini titel om oss</h3>
                        <h1 className="text-5xl font-semibold">Lär känna oss</h1>
                    </div>

                    <div className="flex flex-col gap-10">
                        <p className="pr-[15%] text-[#222]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla molestiae minima architecto facilis earum deleniti! Omnis, iure quidem. Assumenda minima ex nesciunt sint consequatur, perferendis aliquam labore commodi atque architecto!</p>
                        <div className="flex justify-start">
                            <Link to="">
                                <span className="px-6 py-3 uppercase border border-[#0f1920] bg-[#0f1920] text-white hover:bg-transparent hover:text-[#0f1920] transition-all duration-300">Kontakta oss</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="w-1/2 grid grid-cols-2 gap-20 max-md:w-full max-md:gap-10">
                    {usermapItems.map((item, index) => (
                        <div key={index} className="col-span-1 flex flex-col gap-2.5 bg-[#0f1920] p-6 rounded-lg">
                            <div className="">
                                <img className="object-cover w-full h-auto rounded-t-lg" src={item.image} alt="" />
                            </div>
                            <div className="">
                                <h2 className="text-white text-xl font-semibold">{item.name}</h2>
                                <h3 className="text-white text-xs uppercase">{item.title}</h3>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default About;