interface Props {
  image: string;
  cover: string;
  date: string;
  title: string;
  description: string;
  link: string;
}

function Post({ ...props }: Props) {
  return (
    <div className="w-full flex col-span-1 gap-3 max-lg:flex-col">
      <a
        className="w-3/6 h-[16rem] relative overflow-hidden rounded-lg group hover:cursor-pointer max-lg:w-full max-lg:h-[20rem]"
        href={props.link}
        target="_blank"
        rel="noreferrer"
      >
        <div
          className="w-full h-full bg-center bg-cover bg-no-repeat rounded-lg duration-300 ease-in-out group-hover:scale-110"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .1), rgba(0,0,0, .1)), url(${props.image})`,
          }}
        ></div>
      </a>

      <div className="w-3/6 h-full flex items-center max-lg:w-full max-lg:h-min">
        <div className="flex flex-col justify-start gap-5 h-full max-lg:gap-3">
          <h1 className="text-lg uppercase font-semibold tracking-wide text-[#222]">
            {props.title}
          </h1>
          <p className="text-[#222] opacity-80 text-sm">{props.description}</p>
          <div className="">
            <div className="">
              <img src="" alt="" /> {/* Icon */}
              <p className="text-[#222] text-xs">{props.date}</p> {/* Date */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Post;
