import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 59.3163881228119, // Replace with your desired initial latitude
  lng: 18.16262419890244, // Replace with your desired initial longitude
};

const mapOptions = {
  styles: [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        {
          saturation: -100, // Set saturation to -100 for grayscale
        },
      ],
    },
  ],
};

const GoogleMaps: React.FC = () => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyClC7gXbYTr1-IIWAkrlZL50YZvvOY9jts">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        options={mapOptions} // Apply custom map style here
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMaps;
