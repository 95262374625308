import Hero from "./sections/hero";
import Offer from "./sections/offer";
import About from "./sections/about";
import Partners from "./sections/partners";
import Contact from "./sections/contact";

function Home() {
  return (
    <>
      <Hero />
      <Offer />
      <Partners />
      <About />
      <Contact />
    </>
  );
}

export default Home;
