import Offers from "../../../components/offers-boxes/offers";
import Bolt from "../../../assets/icons/bolt.svg";
import LED from "../../../assets/icons/led.svg";
import Network from "../../../assets/icons/network.svg";

function Offer() {
  return (
    <section className="container mx-auto pb-20 pt-20 max-md:px-[5%]">
      <div className="flex flex-col justify-between gap-10">
        <div className="flex justify-center">
          <h1 className="text-4xl font-semibold tracking-wide max-sm:text-2xl">
            Vad erbjuder vi för tjänster?
          </h1>
        </div>

        <div className="flex justify-between gap-8 max-lg:flex-wrap max-md:flex-col max-md:gap-10 max-sm:flex-col max-sm:gap-10">
          <Offers
            icon={LED}
            title="LED Konverteringar"
            text="Elektropartner är experter på LED konverteringar. Med moderna LED-lampor kan de göra din belysning energieffektivare och miljövänligare, samtidigt som du sparar pengar. Kontakta Elektropartner idag för att få skräddarsydda lösningar och förbättra din belysning."
            duration={300}
            alt=""
          />
          <Offers
            icon={Bolt}
            title="Elinstallatör"
            text="Upptäck vår elektriska expertis, specialiserade på omfattande projekt med utförandeentreprenader och nyckelfärdiga lösningar. Klientel inkluderar byggföretag, fastighetsbolag och offentliga förvaltningar. Elektropartner - din pålitliga partner för elinstallationer. Kontakta oss för kompetens och pålitlighet."
            duration={600}
            alt=""
          />
          <Offers
            icon={Network}
            title="Fiber & Datainstallation"
            text="På Elektropartner märker vi en ökad efterfrågan på data- och fibertjänster, särskilt i kontorsmiljöer. Vi är stolta över vårt arbete inom fiberoptik och datainstallation, vilket stärker våra kundrelationer. Vår hängivenhet till excellens har lett till utökad närvaro inom området. Vi strävar efter förstklassiga tjänster."
            duration={900}
            alt=""
          />
        </div>
      </div>
    </section>
  );
}

export default Offer;
