import { useNavigate } from "react-router-dom";
import LinkedIn from "./../../assets/icons/linkedin.svg";
import Instagram from "./../../assets/icons/instagram.svg";
import Facebook from "./../../assets/icons/facebook.svg";

const MenuOverlay = ({ navbarOpen, setNavbarOpen }) => {
  let navigate = useNavigate();

  const handleNavigate = (e, destination) => {
    navigate(destination);
    e.preventDefault();
    setNavbarOpen(false);

    document.body.style.overflowY = "initial";
  };

  return (
    <nav
      id="megaMenu"
      className={`fixed flex justify-center items-center w-full pl-12 md:pr-0 md:w-[100%] xl:pl-20 z-40 h-screen pt-24 pb-24 bg-[#0f1920] text-white bg-opacity-[85%] transform delay-200 transition-all duration-1000 backdrop-blur-md ${
        navbarOpen ? "opacity-100 translate-x-0" : "opacity-0 translate-x-full"
      }`}
    >
      <div className="flex flex-col w-full gap-10">
        <ul className="flex flex-col items-start justify-end">
          <div className="pb-2">
            <h1 className="text-xl font-semibold tracking-widest text-white uppercase opacity-40 max-md:text-sm">
              Navigator
            </h1>
          </div>

          <li className="nav-li mb-3">
            <a
              className="text-4xl text-white uppercase font-bold tracking-wide hover:opacity-70 duration-300 ease-in-out cursor-pointer hover:tracking-widest max-md:text-2xl"
              onClick={(e) => {
                handleNavigate(e, "/");
              }}
            >
              Hem
            </a>
          </li>
          <li className="nav-li mb-3">
            <a
              className="text-4xl text-white uppercase font-bold tracking-wide hover:opacity-70 duration-300 ease-in-out cursor-pointer hover:tracking-widest max-md:text-2xl"
              onClick={(e) => {
                handleNavigate(e, "/nyheter");
              }}
            >
              Nyheter
            </a>
          </li>
          <li className="nav-li mb-3">
            <a
              className="text-4xl text-white uppercase font-bold tracking-wide hover:opacity-70 duration-300 ease-in-out cursor-pointer hover:tracking-widest max-md:text-2xl"
              onClick={(e) => {
                handleNavigate(e, "/projekt");
              }}
            >
              Projekt
            </a>
          </li>
          <li className="nav-li mb-3">
            <a
              className="text-4xl text-white uppercase font-bold tracking-wide hover:opacity-70 duration-300 ease-in-out cursor-pointer hover:tracking-widest max-md:text-2xl"
              onClick={(e) => {
                handleNavigate(e, "/styrteknik");
              }}
            >
              Styrteknik
            </a>
          </li>
          <li className="nav-li mb-3">
            <a
              className="text-4xl text-white uppercase font-bold tracking-wide hover:opacity-70 duration-300 ease-in-out cursor-pointer hover:tracking-widest max-md:text-2xl"
              onClick={(e) => {
                handleNavigate(e, "/kontakta-oss");
              }}
            >
              Kontakta oss
            </a>
          </li>
          <li className="nav-li mb-3">
            <a
              className="text-4xl text-white uppercase font-bold tracking-wide hover:opacity-70 duration-300 ease-in-out cursor-pointer hover:tracking-widest max-md:text-2xl"
              onClick={(e) => {
                handleNavigate(e, "/jobb");
              }}
            >
              Jobba med oss
            </a>
          </li>
        </ul>

        <div className="flex gap-4">
          <a
            className=""
            href="https://www.linkedin.com/company/elektropartner-nacka-ab/"
            target="_blank"
          >
            <img
              className="w-6 h-auto ease-in-out duration-300 hover:cursor-pointer hover:opacity-70"
              src={LinkedIn}
              alt=""
            />
          </a>

          <a
            className=""
            href="https://www.facebook.com/profile.php?id=100068025891965"
            target="_blank"
          >
            <img
              className="w-6 h-auto ease-in-out duration-300 hover:cursor-pointer hover:opacity-70"
              src={Facebook}
              alt=""
            />
          </a>

          <a
            className=""
            href="https://www.instagram.com/elektropartnernacka/"
            target="_blank"
          >
            <img
              className="w-6 h-auto ease-in-out duration-300 hover:cursor-pointer hover:opacity-70"
              src={Instagram}
              alt=""
            />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default MenuOverlay;
