interface Props {
  img: string;
  alt: string;
  url: string;
  column1?: boolean;
  column2?: boolean;
  column3?: boolean;
  column4?: boolean;
}

function Partner({ ...props }: Props) {
  return (
    <a
      className={`flex justify-center items-center ${
        props.column1
          ? "col-span-1 "
          : props.column2
            ? "col-span-2"
            : props.column3
              ? "col-span-3 max-md:col-span-1"
              : props.column4
                ? "col-span-4 max-md:col-span-1"
                : ""
      }`}
      href={props.url}
      target="_blank"
      rel="noreferrer"
    >
      <img
        className="w-[18rem] h-auto max-lg:min-w-[12rem] max-md:min-w-[12rem]"
        src={props.img}
        alt={props.alt}
      />
    </a>
  );
}

export default Partner;
