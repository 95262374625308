import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Header, Footer, MenuOverlay } from "./components/index";
import { Home, Apply, Contact, Projects, News, Styrteknik } from "./pages/index";
import ProjectDetails from "./components/project-item/ProjectDetails";
import "./scrollbar.css";

function App() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <>
      <Header navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
      <MenuOverlay navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/jobb" element={<Apply />} />
        <Route path="/styrteknik" element={<Styrteknik />} />
        <Route path="/kontakta-oss" element={<Contact />} />
        <Route path="/projekt" element={<Projects />} />
        <Route path="/projekt/:link" element={<ProjectDetails />} />
        <Route path="/nyheter" element={<News />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
