import React from "react";
import { Link } from "react-router-dom";

interface ServiceItem {
    number: string;
    title: string;
    details: string;
}

const serviceItems: ServiceItem[] = [
    {
        number: '01',
        title: 'Service & underhåll',
        details: 'Vårt team skickliga installatörer och tekniker står redo att erbjuda service och underhållstjänster i hela Stockholmsområdet. Tack vare vår breda kompetens av styrsystem hanterar vi allt från rutinmässigt underhåll till komplexa reparationer.'
    },
    {
        number: '02',
        title: 'Programmering ​& driftsättning',
        details: 'Vi utför programmering och driftsättning av diverse PLC/DUC-system. Vi är certifierade systemintegratörer för SAIA PLC-system.',
    },
    {
        number: '03',
        title: 'Projektering & konstruktion',
        details: 'Vi projekterar och konstruerar pålitliga, energieffektiva styr-, regler- och övervakningssystem. Vi arbetar i nära dialog med våra kunder genom hela processen: från inledande inventeringar och energiutredningar till framställning av system- och relationshandlingar, en godkänd besiktning och slutlig överlämnande av dokumentation. Vi behärskar varje steg i kedjan och vårt fokus är att leverera högsta möjliga värde för våra kunder.',
    },
    {
        number: '04',
        title: 'Styrinstallation',
        details: 'Vårt team av kvalificerade installatörer och tekniker hanterar allt från styrservice till kompletta installationer av apparatskåp utrustade med PLC-system - för samtliga typer av fastigheter. Vi fokuserar på funktion, spårbarhet och driftsäkerhet.',
    },
    {
        number: '05',
        title: 'HMI / DHC - operatörspaneler',
        details: 'Vi utvecklar moderna grafiska användargränssnitt med hjälp av etablerade programvaror som Web Port, Beijer IX, WebEditor 8 m.fl. För kunder som redan använder överordnade system och har egna SCADA-integratörer har vi lång erfarenhet av att leverera underlag för driftbilder och samordna funktionskontroller.',
    }
];

const Services: React.FC = () => {
    return (
        <section className="container mx-auto pb-20 pt-20 max-md:px-[5%]">
            <div className="w-full">
                <div className="grid grid-cols-3 gap-10 max-lg:grid-cols-2 max-md:grid-cols-1">
                    {serviceItems.map((item, index) => (
                        <div key={index} className="flex flex-col col-span-1 text-[#222]">
                            <h1 className="text-5xl text-[#0f1920] font-bold h-[3.5rem]">{item.number}</h1>
                            <div className="">
                                <h3 className="text-3xl font-semibold h-[5rem] max-md:h-[3rem]">{item.title}</h3>
                                <p className="">{item.details}</p>
                            </div>
                        </div>
                    ))}
                        <div className="col-span-1 bg-[#0f1920] text-white rounded-lg flex flex-col justify-between p-10 max-md:gap-16">
                            <h3 className="text-3xl font-semibold h-[5rem]">Kontakta oss, och få reda på mer om våra tjänster.</h3>
                            <div className="flex justify-start">
                                <Link to="/kontakta-oss">
                                    <span className="px-6 py-3 uppercase bg-white border text-[#0f1920] hover:bg-transparent hover:text-white transition-all duration-300">Kontakta oss</span>
                                </Link>
                            </div>
                        </div>
                </div>
            </div>
        </section>
    );
}

export default Services;