import { Link } from "react-router-dom";
import Image from "./../../../assets/images/hero.jpg";

function Hero() {
  return (
    <section
      className="w-full h-screen bg-[#6e85ad] bg-cover bg-center bg-no-reapeat"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(15, 25, 32, .65), rgba(15, 25, 32, .65)), url(${Image})`,
      }}
    >
      <div className="w-full h-full flex justify-start items-center">
        <div className="flex flex-col gap-12 px-[15%] max-lg:gap-6 max-lg:px-[10%] max-sm:gap-4 max-sm:px-[5%]">
          <h1 className="text-5xl text-white font-semibold tracking-wider max-lg:text-4xl max-sm:text-2xl">
            Säker och Effektiv Elektrisk<br></br>Expertis med Elektropartner
          </h1>
          <p className="text-lg text-white font-light tracking-wide max-lg:text-base max-sm:text-xs">
            Från kretsar till lösningar, tar vi hand om dina elektriska<br></br>
            behov med precision och expertis.
          </p>
          <div className="flex gap-5 max-lg:gap-3 max-sm:gap-2">
            <Link
              to="/kontakta-oss"
              className="px-12 py-3 border border-[#0f1920] bg-[#0f1920] bg-opacity-60 border-opacity-80 backdrop-blur-lg text-white text-lg tracking-wider rounded-lg max-lg:text-base max-lg:px-8 max-lg:py-2 max-sm:text-xs max-sm:px-6 max-sm:py-2 hover:cursor-pointer hover:bg-opacity-40 duration-300 ease-in-out"
            >
              Kontakta oss
            </Link>
            <Link
              to="/projekt"
              className="px-12 py-3 border border-[#0f1920] bg-[#0f1920] bg-opacity-60 border-opacity-80 backdrop-blur-lg text-white text-lg tracking-wider rounded-lg max-lg:text-base max-lg:px-8 max-lg:py-2 max-sm:text-xs max-sm:px-6 max-sm:py-2 hover:cursor-pointer hover:bg-opacity-40 duration-300 ease-in-out"
            >
              Alla projekt
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
